<template>
    <div id="knife" v-if="!loading" :class="{ 'non-member': !thisIndex }">
        <a v-if="thisIndex" :href="siteList[previousIndex]">
            <img src="https://knifebeetle.neocities.org/js/prev.png"></a>
        <a href='https://knifebeetle.neocities.org/'>
            <img class="webring-logo" src='https://knifebeetle.neocities.org/images/kb1-white.png'
                alt="This site is part of the KNIFEBEETLE webring"></a>
        <a v-if="thisIndex" :href="siteList[nextIndex]">
            <img src="https://knifebeetle.neocities.org/js/next.png"></a>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';

const loading = ref(true)
const siteList = ref([])
const previousIndex = ref(null);
const thisIndex = ref(null);
const nextIndex = ref(null);

onMounted(() => {
    checkLoading()
})

function checkLoading() {
    if (typeof ringName == 'undefined') setTimeout(() => {
        checkLoading();
    }, 100);
    else setupWidget()
}

function setupWidget() {
    // eslint-disable-next-line no-undef
    siteList.value = sites // global var from 3rd party .js
    let thisSite = window.location.href; //get the url of the site we're currently on

    // go through the site list to see if this site is on it and find its position
    for (let i = 0; i < siteList.value.length; i++) {
        if (thisSite.startsWith(siteList.value[i])) { //we use startswith so this will match any subdirectory, users can put the widget on multiple pages
            thisIndex.value = i;
            break; //when we've found the site, we don't need to search any more, so stop the loop
        }
    }
    previousIndex.value = (thisIndex.value - 1 < 0) ? siteList.value.length - 1 : thisIndex.value - 1;
    nextIndex.value = (thisIndex.value + 1 >= siteList.value.length) ? 0 : thisIndex.value + 1;
    loading.value = false
}

</script>

<style lang="scss" scoped>
#knife {
    display: grid;
    grid-template-columns: 1fr 4fr 1fr;
    align-items: center;
    justify-items: center;
    border-radius: 10px;
    padding: 0 5px;
    width: 167px;
    background: #FFFFFF;

    &.non-member {
        display: flex;
        width: 90px;
        border-radius: 3px;
    }

    >a {
        display: grid;
        place-items: center;
    }

    .webring-logo {
        // width: 150px;
        // height: 38px;
    }
}
</style>