<template>
    <div id="home" class="screen">
        <WorldMap />
        <section class="hero is-fullheight-with-navbar">
            <div class="hero-head has-text-centered">
                <img class="mt-6" src="@/assets/img/tltc-logo.png" />
                <h2 class="subtitle">
                    An animated webcomic about finding treasures in a world made
                    of trash.
                </h2>

                <div class="buttons is-centered">
                    <a v-if="!showResume" @click="startEpisode(0)"
                        class="button is-light is-outlined is-hidden-touch">Start at the beginning</a>
                    <a @click="startEpisode(0)" class="button is-light is-outlined is-hidden-desktop">Start
                        <span v-if="!showResume">&nbsp;at the beginning</span></a>
                    <a v-if="showResume" @click="resumeEpisode"
                        class="button is-light is-outlined is-hidden-desktop">Resume</a>
                    <a @click="latestEpisode" class="button is-light is-outlined is-hidden-desktop">Latest</a>
                </div>
                <span style="font-family: 'ransomNote'; opacity: 0">
                    <i class="fa-solid fa-compact-disc"></i>
                </span>
                <span style="font-family: 'boldlyMissy'; opacity: 0"></span>
            </div>
            <div class="hero-body has-text-centered">
                <div class="container px-4">
                    <!-- <div class="notification is-link is-light">
                        <button class="delete"></button>
                        News messages here
                    </div> -->
                </div>
            </div>
            <div class="hero-footer">
                <div class="mx-4">
                    <KnifeBeetleWebring />
                </div>
                <div class="tags content-tags">
                    <div class="tag is-success">post-apocalypse</div>
                    <div class="tag is-success">slice of life</div>
                    <div class="tag is-success">rated teen</div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import WorldMap from "@/components/WorldMap.vue";
import KnifeBeetleWebring from "../components/KnifeBeetleWebring.vue";

export default {
    name: "Home",
    components: {
        WorldMap,
        KnifeBeetleWebring

    },
    data() {
        return {
            loaded: false
        };
    },
    mounted() {
        this.loaded = true;
        window.scrollTo(0, 0);
        // console.log("---- onMounted Home.vue")
    },
    // beforeUnmount() {
        // console.log("---- Delete KnifeBeetle JS")
    // },
    computed: {
        ...mapGetters(["episodeNumber", "latestUpdate", "currentPanel"]),
        showResume() {
            if (this.episodeNumber && this.currentPanel > 0) return true;
            if (localStorage.episodeNumber && localStorage.currentPanel)
                return true;
            return false;
        },
    },
    methods: {
        ...mapMutations(["setEpisodeNumber", "setPaused", "showModal"]),
        startEpisode(num) {
            if (!this.showResume) {
                this.setEpisodeNumber(num);
                this.showModal("playerMode");
            } else {
                this.setPaused(false);
                // this.$store.dispatch("loadEpisode", { episode: num, panel: 0 });
                this.$router.push({
                    name: "Read",
                    query: { episode: num },
                });
            }
        },
        resumeEpisode() {
            if (this.routeName !== "Read") {
                document.activeElement.blur();
                const num = this.episodeNumber ? this.episodeNumber : 0;
                const panel = this.currentPanel ? this.currentPanel : 0;
                // this.$store.dispatch("loadEpisode", {
                //     episode: num,
                //     panel: panel,
                // });
                this.setPaused(true);
                this.$router.push({
                    name: "Read",
                    query: {
                        episode: num + "." + panel,
                    },
                });
                setTimeout(() => {
                    this.setPaused(false);
                }, 100);
            }
        },
        latestEpisode() {
            const num =
                this.latestUpdate.episode + "." + this.latestUpdate.panel;
            this.setPaused(false);
            // this.$store.dispatch("loadEpisode", this.latestUpdate);
            this.$router.push({
                name: "Read",
                query: { episode: num },
                // { name: 'About', hash: anchor }
            });
        },
    },
};
</script>
